import React from 'react'
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./Desktop2.css";

const Contactus = () => {
    return (
        <div className="desktop-3">
            <Header
                vector13Stroke="/vector-13.svg"
                vector13Stroke1="/vector-131.svg"
                vector13StrokeHeight="unset"
                vector13StrokeMaxHeight="100%"
                vector13StrokeHeight1="unset"
                vector13StrokeMaxHeight1="100%"
            />
            <br></br>
            <div className="frame-parent16">
            <div className="bnmit2" style={{alignSelf:"inherit"}}><b>Terms and conditions</b></div>
                <div className="frame-parent17">
                    <div className="what-is-tedx-bnmit-wrapper">
                        <div className="the-team">
                            <p className="tedxbnmit-is-a-dynamic-platfor">Refund and<br></br> cancellation<br></br>policy</p>
                        </div>
                    </div>
                    <div className="tedxbnmit-is-a-dynamic-platfor-wrapper">
                        <div className="tedxbnmit-is-a-container">
                            <p className="tedxbnmit-is-a-dynamic-platfor">
                                <span className="is-a-dynamic">
                                    <li>This registration is non-refundable, non-transferable, and does not allow for cancellation or rescheduling; it is valid for one person only with no refunds for no-shows</li>
                                    <li>By Registering and making a payment you agree that all details provided are accurate</li>
                                    <li>All registrations are subject to verification, any discrepancy may result in the cancellation of the registration</li>
                                    <li>Make sure you carry the ticket details on your phone/printout for verification</li>
                                    <li>All attendees must carry a valid government identity card to attain a conference attendee badge at the venue</li>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="frame-parent17">
                    <div className="what-is-tedx-bnmit-wrapper">
                        <div className="the-team">
                            <p className="tedxbnmit-is-a-dynamic-platfor">Privacy <br></br> and Policy</p>
                        </div>
                    </div>
                    <div className="tedxbnmit-is-a-dynamic-platfor-wrapper">
                        <div className="tedxbnmit-is-a-container">
                            <p className="tedxbnmit-is-a-dynamic-platfor">
                                <span className="is-a-dynamic">
                                    <li>Please cooperate with private security and police personnel for security checks</li>
                                    <li>We recommend using your masks and sanitizers, and being mindful of social distancing in the break-out areas. The seating in the auditorium takes care of the social distance indoors.</li>
                                    <li>To ensure your safety, the organizers reserve the rights to frisk and restrict entry</li>
                                    <li>The consumption of any prohibited substances is strictly banned</li>
                                    <li>Carrying of food items, alcohol, cigarettes, weapons, inflammable and banned substances are strictly prohibited</li>
                                    <li>The organizer does not take responsibility for injuries, loss or theft of any personal belongings of the ticket holder</li>
                                    <li>Any individual might be asked to leave the venue without any liability of a refund if there is any behavioral misdemeanor</li>
                                    <li>This decision rests with the conference and venue management and is final and binding</li>
                                    <li>All attendees must abide by the rules and regulations set by the organisers</li>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="frame-parent17">
                    <div className="what-is-tedx-bnmit-wrapper">
                        <div className="the-team">
                            <p className="tedxbnmit-is-a-dynamic-platfor">The Event Day</p>
                        </div>
                    </div>
                    <div className="tedxbnmit-is-a-dynamic-platfor-wrapper">
                        <div className="tedxbnmit-is-a-container">
                            <p className="tedxbnmit-is-a-dynamic-platfor">
                                <span className="is-a-dynamic">
                                    <li>Entry into the auditorium will only start 20 mins before the event timing</li>
                                    <li>Closed gates are sacrosanct during TEDxBNMIT sessions. Opening them disrupts the speaker’s flow, disturbs the audience’s experience, and adds a ‘gate-crasher’ cameo to our video recordings. Let us keep our gates closed, minds open, and recordings disturbance-free</li>
                                    <li>Attendees reaching late at the venue will NOT be allowed inside the auditorium while talks are in progress</li>
                                    <li>The attendees must wear their conference badge throughout the conference across all venues</li>
                                    <li>There will be adequate breaks for networking and refreshments. Lunch and tea are included in the ticket price.</li>
                                    <li>For any queries with respect to venue logistics, parking, toilets, please reach out to our uniformed volunteers</li>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="frame-parent17">
                    <div className="what-is-tedx-bnmit-wrapper">
                        <div className="the-team">
                            <p className="tedxbnmit-is-a-dynamic-platfor">The <br></br>Best Practices</p>
                        </div>
                    </div>
                    <div className="tedxbnmit-is-a-dynamic-platfor-wrapper">
                        <div className="tedxbnmit-is-a-container">
                            <p className="tedxbnmit-is-a-dynamic-platfor">
                                <span className="is-a-dynamic">
                                    <li>We encourage you to carpool with other attendees or use public transport, and get to know fellow attendees before the event (Find #TEDxBNMITCarpoolers online on social media)</li>
                                    <li>We request you not to leave half-finished water bottles lying around the venue</li>
                                    <li>Please ensure that the event spaces are kept litter free</li>
                                    <li>During sessions, please keep your mobile phones on silent mode or switched off. A ringing phone is a disturbance to the speaker as well as other attendees</li>
                                    <li>No flash photography and recording of the event is allowed</li>
                                    <li>Maintain silence during speaker talks and performances to get the best out of the talk</li>
                                    <li>Do not hesitate in giving the speaker a standing ovation if their talk moved you</li>
                                    <li>Any movement during the talks and performances are strongly discouraged</li>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bnmit2" style={{alignSelf:"inherit"}}><b>Venue Details</b></div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1944.3883720050253!2d77.56592766428093!3d12.922065827192347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1581f297e32d%3A0xe9a257cf4383019e!2sBNM%20Institute%20of%20Technology!5e0!3m2!1sen!2sin!4v1710285529242!5m2!1sen!2sin"
                    width="900"
                    height="500"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    className='map'
                ></iframe>

                <div className='bnmit2' style={{"textAlign":"center"}}>TEDxBNMIT Managed By <br></br><b>BHAGEERATHI BAI NARYANA RAO MANAAY CHARITIES</b></div>
                <div className="bnmit2" style={{fontSize:"20px","textAlign":"center"}}>For Booking Queries Contact<br></br>Karthik : +918660444707<br></br>tedx@bnmit.in</div>
                <br></br>
            </div>
            <Footer />
        </div>
    );
};

export default Contactus;
