import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="frame-parent40">
      <div className="dreams-unlocked-1-container">
        <img
          className="dreams-unlocked-13"
          alt=""
          src="/images/dreams-unlocked-12@2x.png"
        />
        <Link to="/booking">
          <div className="confirmation-number-parent">
            <img
              className="confirmation-number-icon"
              alt=""
              src="/images/confirmation-number.svg"
            />
            <div className="dreams-unlocked">Book Tickets</div>
          </div>
        </Link>
        {/* <div className="terms-and-conditions" style={{fontWeight:"bold"}}>For Booking Queries Contact<br></br> Karthik : +918660444707</div> */}
      </div>
      <div className="links-parent">
        <b className="links">Links</b>
        <img
          className="vector-12-stroke3"
          alt=""
          src="/images/vector-12-stroke1.svg"
        />
        <Link to="/"><div className="terms-and-conditions">Dreams Unlocked</div></Link>
        <Link to="/aboutus"><div className="terms-and-conditions">About TEDxBNMIT</div></Link>
        <div className="terms-and-conditions">Sponsors</div>
      </div>
      <div className="links-parent">
        <b className="links">Other Links</b>
        <img
          className="vector-12-stroke3"
          alt=""
          src="/images/vector-12-stroke2.svg"
        />
        <Link to="/contactus"><div className="terms-and-conditions">Terms and conditions</div></Link>
        <Link to="/contactus"><div className="terms-and-conditions">Refund & cancellation policy</div></Link>
        <Link to="/contactus"><div className="terms-and-conditions">Privacy and Policy</div></Link>
        <Link to="/contactus"><div className="terms-and-conditions">Contact</div></Link>
      </div>
      <div className="follow-us-on-social-media-parent">
        <b className="follow-us-on-container">
          <p className="follow-us-on">Follow us on</p>
          <p className="follow-us-on">Social Media!</p>
        </b>
        <img
          className="vector-12-stroke3"
          alt=""
          src="/images/vector-12-stroke3.svg"
        />
        <div className="iconinstagram-parent9">
          <a href="https://www.instagram.com/tedxbnmit?igsh=MXIzbTJpMXRlMnJqOA=="><img className="iconinstagram11" alt="" src="/images/iconinstagram1.svg" /></a>
          < a href="https://www.facebook.com/share/6yVcd2twGMkRjxiV/?mibextid=sCpJLy"><img className="iconinstagram11" alt="" src="/images/iconfacebook1.svg" /></a>
          <a href="https://www.linkedin.com/company/tedxbnmit/"><img className="iconinstagram11" alt="" src="/images/iconlinkedin1.svg" /></a>
        </div>
      </div>
      <img className="logo-icon3" alt="" src="/images/logo2@2x.png" />
      <div className='copyright-text'><a href="https://www.instagram.com/siggraph.bnmit" target="_blank"><span>Website developed and designed by </span><b> Siggraph BNMIT &copy; 2024 </b></a></div>
    </div>
  );
};

export default Footer;
