import React from 'react';
import './Loading.css'
import loader from "../loading.gif"
const Loading = () => {
  return (
    <div className="loading-spinner">
      <img src={loader} className='gif' />
      {/* <p className="load-text">Loading...</p>  */}
    </div>


  );
};

export default Loading;
