import "./CountdownContainer.css";

const CountdownContainer = ({ imageDimensionsCode, countdownText }) => {
  return (
    <div className="frame-parent39">
      <div className="group-wrapper9">
        <img className="frame-child39" alt="" src={imageDimensionsCode} />
      </div>
      {/* <div className="revealing-in-2-days-parent">
        <div className="revealing-in-2">{countdownText}</div>
        <div className="theatre-artist">THEATRE ARTIST</div>
      </div> */}
    </div>
  );
};

export default CountdownContainer;
