import "./EventDetailsContainer.css";

const EventDetailsContainer = ({ imageResolutionId }) => {
  return (
    <div className="event-details-parent">
      <b className="event-details">Event Details</b>
      <img className="vector-12-stroke2" alt="" src={imageResolutionId} />
      <div className="date-23rd-march-container">
        <span>Date:</span>
        <span className="rd-march-2024"> 23rd March 2024</span>
      </div>
      <div className="date-23rd-march-container">
        <span>Venue:</span>
        <span className="rd-march-2024">
          {" "}
          Mathrushree Sushila Devi R Maanay Auditorium BNM Institute of
          Technology, 12th Main Road, 27th Cross, Banashankari Stage II,
          Bengaluru, Karnataka 560070
        </span>
      </div>
    </div>
  );
};

export default EventDetailsContainer;
