import "./SectionComponent.css";
import { Link } from "react-router-dom";

const SectionComponent = () => {
  return (
    <div className="frame-parent33">
      <div className="frame-parent34">
        <img className="frame-child35" alt="" src="/images/img1.png" />
        <img className="frame-child35" alt="" src="/images/img2.png" />
        <img className="frame-child35" alt="" src="/images/img3.png" />
      </div>
      <div className="image-4-group">
        <img className="image-4-icon1" alt="" src="/images/image-4@2x.png" />
        <div className="frame-parent35">
          <div className="frame-parent36">
            <div className="frame-parent37">
              <div className="logo-group">
                <img className="logo-icon2" alt="" src="/images/logo1@2x.png" />
                <div className="presents-wrapper">
                  <div className="presents">presents</div>
                </div>
              </div>
              <div className="frame-parent38">
                <img className="frame-child38" alt="" src="/images/frame-191.svg" />
                <div className="dreams-parent">
                  <img className="dreams-icon" alt="" src="/images/dreams.svg" />
                  <img className="unlocked-icon" alt="" src="/images/unlocked.svg" />
                </div>
              </div>
            </div>
            <div className="mathrushree-sushila-devi-maana-parent">
              <div className="mathrushree-sushila-devi">
                Mathrushree Sushila Devi Maanay Auditorium, BNMIT
                {/* {window.innerWidth} */}
              </div>
              <img
                className="vector-11-stroke"
                alt=""
                src="/images/vector-11-stroke.svg"
              />
              <div className="rd-march-2024-container">
                <p className="rd-march">
                  <b>23rd March</b>
                </p>
                <p className="p">2024</p>
              </div>
            </div>
          </div>
          <Link to="/booking">
            <div className="confirmation-number-parent">
              <img
                className="confirmation-number-icon"
                alt=""
                src="/images/confirmation-number.svg"
              />
              <div className="dreams-unlocked">Book Tickets</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SectionComponent;
