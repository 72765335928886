import Header from "../components/Header";
import SectionComponent from "../components/SectionComponent";
import EventDetailsContainer from "../components/EventDetailsContainer";
import CountdownContainer from "../components/CountdownContainer";
import Footer from "../components/Footer";
import "./Desktop.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Desktop = () => {
  return (
    <div className="desktop-1">
      <Header
        vector13Stroke="/vector-13-stroke.svg"
        vector13Stroke1="/vector-13-stroke1.svg"
      />
      <SectionComponent />
      <div className="frame-parent">
        <div className="frame-group">
          <div className="what-is-ted-wrapper">
            <div className="what-is-ted-container">
              <p className="what-is">What is</p>
              <p className="ted">
                <span className="ted1">TED</span>
                <span className="span">?</span>
              </p>
            </div>
          </div>
          <div className="ted-is-a-nonprofit-organizatio-wrapper">
            <div className="ted-is-a-container">
              <span className="ted2">TED</span>
              <span className="is-a-nonprofit">
                <span className="span2">{` `}</span>
              </span>
              <span className="is-a-nonprofit-organization-">
                <span className="is-a-nonprofit">
                  is a nonprofit organization devoted to Ideas Worth Spreading,
                  often in the form of short talks delivered by leading thinkers
                  and doers. Many of these talks are given at TED Conferences,
                  intimate TED Salons and thousands of independently organized
                  TEDx events around the world. Videos of these talks are made
                  available, free, on </span>
                <span className="tedcom">TED.com</span>
                <span className="is-a-nonprofit"> and other platforms.</span>
              </span>
            </div>
          </div>
        </div>
        <div className="frame-group">
          <div className="what-is-ted-wrapper">
            <div className="what-is-ted-container">
              <p className="what-is">What is</p>
              <p className="ted">
                <span className="ted1">TEDx</span>
                <span className="span">?</span>
              </p>
            </div>
          </div>
          <div className="tedx-is-a-grassroots-initiativ-wrapper">
            <div className="ted-is-a-container">
              <span className="ted1">TEDx</span>
              <span className="is-a-grassroots-initiative-cr">
                <span className="span2">{` `}</span>
                <span className="is-a-nonprofit">
                  is a grassroots initiative, created in the spirit of TED's
                  overall mission to research and discover “ideas worth
                  spreading.” TEDx brings the spirit of TED to local communities
                  around the globe through TEDx events. These events are
                  organized by passionate individuals who seek to uncover new
                  ideas and to share the latest research in their local areas
                  that spark conversations in their communities.
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-div">
        <div className="frame-wrapper">
          <div className="dreams-unlocked-1-wrapper">
            <img
              className="dreams-unlocked-1"
              alt=""
              src="/images/dreams-unlocked-1@2x.png"
            />
          </div>
        </div>
        <div className="frame-parent1">
          <div className="parent">
            <img className="icon" alt="" src="/images/quotes.png" />
            <div className="ted-is-a-container">
              Don't shy away from your dreams. Embrace your culture with all
              your heart, for every dream is unique and achievable. Whether it's
              as an introvert or an extrovert, a budding artist or a legendary
              performer, we all have the power to make our dreams a reality.
            </div>
          </div>
          <EventDetailsContainer imageResolutionId="/vector-12-stroke.svg" />
        </div>
      </div>
      <div className="frame-parent2">
        <div className="frame-parent3">
          <div className="dreams-unlocked-1-parent">
            <img
              className="dreams-unlocked-11"
              alt=""
              src="/images/dreams-unlocked-11@2x.png"
            />
            <b className="what-is-ted-container">SPEAKERS</b>
          </div>

          {/* <div className="group-wrapper9-first">
            <img className="frame-child39-first" alt="" src="/images/gagan.png" />
          </div> */}

          <CountdownContainer
            imageDimensionsCode="/images/1aditi.png"
            countdownText="Revealing in 3 days"
          />

          {/* <div className="prakash-belawadi-parent">
            <div className="prakash-belawadi">Prakash Belawadi</div>
            <div className="lorem-ipsum-dolor">
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
              fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
              vitae mattis tellus..
            </div>
            <div className="iconinstagram-parent">
              <img className="iconinstagram" alt="" src="/images/iconinstagram.svg" />
              <img className="iconinstagram" alt="" src="/images/iconfacebook.svg" />
              <img className="iconinstagram" alt="" src="/images/iconlinkedin.svg" />
            </div>
          </div> */}

          {/* <div className="group-wrapper9-first">
            <img className="frame-child39-first" alt="" src="/images/aditi.png" />
          </div> */}

          <CountdownContainer
            imageDimensionsCode="/images/tapesh.png"
            countdownText="Revealing in 3 days"
          />

        </div>
        <div className="frame-parent3">
          {/* 
          <div className="group-wrapper9-second">
            <img className="frame-child39-second" alt="" src="/images/gagan.png" />
          </div>

          <div className="group-wrapper9-second">
            <img className="frame-child39-second" alt="" src="/images/aditi.png" />
          </div> */}

          <CountdownContainer
            imageDimensionsCode="/images/1shruthi.png"
            countdownText="Revealing in 3 days"
          />

          <CountdownContainer
            imageDimensionsCode="/images/gagan.png"
            countdownText="Revealing in 3 days"
          />

          <CountdownContainer
            imageDimensionsCode="/images/1vineeth.png"
            countdownText="Revealing in 3 days"
          />

          {/* <div className="group-wrapper9-second">
            <img className="frame-child39-second" alt="" src="/images/tapesh.png" />
          </div>

          <div className="group-wrapper9-second">
            <img className="frame-child39-second" alt="" src="/images/vineeth.png" />
          </div>

          <div className="group-wrapper9-second">
            <img className="frame-child39-second" alt="" src="/images/shruthi.png" />
          </div> */}

        </div>
        <div className="bnmit2" style={{ color: "white" }}><b>PERFORMERS</b></div>
        <div className="container2">
          <div className="group-wrapper9-third">
            <img className="frame-child39-third" alt="" src="/images/1natya.png" />
          </div>

          <div className="group-wrapper9-third">
            <img className="frame-child39-third" alt="" src="/images/1satvik.png" />
          </div>

          <div className="group-wrapper9-third">
            <img className="frame-child39-third" alt="" src="/images/shadaj.png" />
          </div>

          <div className="group-wrapper9-third">
            <img className="frame-child39-third" alt="" src="/images/keerthan.png" />
          </div>

        </div>
      </div>
      {/* <div className="frame-parent5">
        <div className="gallery-parent">
          <b className="what-is-ted-container">Gallery</b>
          <div className="tedx-bnmit">
            <span className="ted1">TEDx </span>
            <span className="bnmit">BNMIT</span>
          </div>
        </div>
        <div className="rectangle-parent">
          <div className="frame-child" />
          <div className="frame-child" />
          <div className="frame-child" />
          <div className="frame-child" />
          <div className="frame-child" />
          <div className="frame-child" />
          <div className="frame-child" />
          <div className="frame-child" />
          <div className="frame-child" />
          <div className="frame-child" />
          <div className="frame-child" />
          <div className="frame-child" />
        </div>
        <div className="carousel-container">
          <Carousel
            showThumbs={false}
            infiniteLoop={true}
            interval={5000}
            autoPlay={true}
            emulateTouch={true}
            showStatus={false}
          >
            <div className="frame-child" />
            <div className="frame-child" />
            <div className="frame-child" />
            <div className="frame-child" />
          </Carousel>
        </div>
        <div className="carousel-container">
          <Carousel
            showThumbs={false}
            infiniteLoop={true}
            interval={5000}
            autoPlay={true}
            emulateTouch={true}
            showStatus={false}
          >
            <div className="frame-child" />
            <div className="frame-child" />
            <div className="frame-child" />
            <div className="frame-child" />
          </Carousel>
        </div>
        <div className="carousel-container">
          <Carousel
            showThumbs={false}
            infiniteLoop={true}
            interval={5000}
            autoPlay={true}
            emulateTouch={true}
            showStatus={false}
          >
            <div className="frame-child" />
            <div className="frame-child" />
            <div className="frame-child" />
            <div className="frame-child" />
          </Carousel>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default Desktop;
