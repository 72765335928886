import React, { useEffect } from 'react';
import './Success.css'; 
import { useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";
const Success = () => {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   // Set a timeout to show a message after 2000 milliseconds (2 seconds)
  //   const timeoutId = setTimeout(() => {
  //     navigate("/")
  //   }, 4000);

  //   // Clean up the timeout if the component unmounts or if you want to cancel it for any reason
  //   return () => clearTimeout(timeoutId);
  // }, []);
  return (
    <div className="desktop-3">
      <Header
        vector13Stroke="/vector-13.svg"
        vector13Stroke1="/vector-131.svg"
        vector13StrokeHeight="unset"
        vector13StrokeMaxHeight="100%"
        vector13StrokeHeight1="unset"
        vector13StrokeMaxHeight1="100%"
      />
      {/* <img
        src="images/dreams-unlocked-1@2x.png" 
        alt="tedx"
        className="tedx"
      />   */}
      {/* <img
        src="images/source.gif"
        alt="Success GIF"
        className="success-gif"
      /> */}
      <div className='space'></div>
      <div className='cente'><b>Thank you so much for your amazing response</b></div>
      <p className="success-text">The event was grand success, Dreams Unlocked 23rd March 2024 🎉</p>
      <p className="info-text">For any queries and information contact our communications lead Karthik: +918660444707<br></br> See you all soon until next event 👋🏽 </p>
      {/* <p className="info-text">Interested attendees may still be able to get tickets subject to availabilty and contact our communications lead, Karthik: +918660444707</p> */}
      <br></br><br></br><br></br>
      <Footer />
    </div>
  );
};

export default Success;
