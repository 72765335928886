import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Desktop from "./pages/Desktop";
// import Desktop1 from "./pages/Desktop1";
import Desktop2 from "./pages/Desktop2";
import Success from "./pages/Success";
import Loading from "./components/Loading";
import Contactus from "./pages/Contactus";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 2000); 

    return () => clearTimeout(delay);
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <Routes>
          <Route path="/" element={<Desktop />} />
          <Route path="/booking" element={<Success />} />
          <Route path="/aboutus" element={<Desktop2 />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      )} 
    </div>
  );
}

export default App;
