import { useMemo, useState } from "react";
import "./Header.css";
import { Link, useLocation } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

const Header = ({
  vector13Stroke,
  vector13Stroke1,
  vector13StrokeHeight,
  vector13StrokeMaxHeight,
  vector13StrokeHeight1,
  vector13StrokeMaxHeight1,
}) => {

  const location = useLocation();
  window.scrollTo(0, 0);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const vector13StrokeStyle = useMemo(() => {
    return {
      height: vector13StrokeHeight,
      maxHeight: vector13StrokeMaxHeight,
    };
  }, [vector13StrokeHeight, vector13StrokeMaxHeight]);

  const vector13Stroke1Style = useMemo(() => {
    return {
      height: vector13StrokeHeight1,
      maxHeight: vector13StrokeMaxHeight1,
    };
  }, [vector13StrokeHeight1, vector13StrokeMaxHeight1]);

  return (
    <div className="logo-parent">
      <div className="logo-container">
        <img className="logo-icon1" alt="" src="/images/logo@2x.png" />
        <span className="hamberger-container" onClick={() => setIsHamburgerOpen((value) => !value)}>
          {!isHamburgerOpen ? <MenuRoundedIcon /> : <CloseRoundedIcon />}
        </span>
      </div>
      <div className={`frame-parent30 ${isHamburgerOpen ? "frame-parent30-mobile" : ""}`}>
        
        <Link onClick={() => setIsHamburgerOpen(false)} to="/">
          <div className={`frame-parent31 ${location.pathname === '/' ? 'active' : ''}`}>
            <div className="frame-parent32">
              <img className="frame-child34" alt="" src="/images/frame-19.svg" />
              <div className="dreams-unlocked">Dreams Unlocked</div>
            </div>
            {location.pathname === '/' && <img
              className="vector-13-stroke"
              alt=""
              src="/images/vector-13-stroke.svg"
              style={vector13StrokeStyle}
            />}
          </div>
        </Link>

        <Link onClick={() => setIsHamburgerOpen(false)} to="/aboutus">
          <div className={`about-tedxbnmit-parent ${location.pathname === '/aboutus' ? 'active' : ''}`}>
            <div className="frame-parent32">
            <img className="frame-child34" alt="" src="/images/frame-19.svg" />
              <div className="dreams-unlocked">About TEDxBNMIT</div>
            </div>
            {location.pathname === '/aboutus' && <img
              className="vector-13-stroke"
              alt=""
              src="/images/vector-13-stroke.svg"
              style={vector13StrokeStyle}
            />}
          </div>
        </Link>


        <Link onClick={() => setIsHamburgerOpen(false)} to="/contactus">
          <div className={`frame-parent31 ${location.pathname === '/contactus' ? 'active' : ''}`}>
            <div className="frame-parent32">
            <img className="frame-child34" alt="" src="/images/frame-19.svg" />
              <div className="dreams-unlocked">Contact us</div>
            </div>
            {location.pathname === '/contactus' && <img
              className="vector-13-stroke"
              alt=""
              src="/images/vector-13-stroke.svg"
              style={vector13StrokeStyle}
            />}
          </div>
        </Link>

        {/* <div className={`about-tedxbnmit-parent ${location.pathname === '/aboutus' ? 'active' : ''}`}>
          <Link onClick={() => setIsHamburgerOpen(false)} to="/aboutus">
            <div className="dreams-unlocked">About TEDxBNMIT</div>
            {location.pathname === '/aboutus' && <img
              className="vector-13-stroke"
              alt=""
              src="/images/vector-13-stroke.svg"
              style={vector13StrokeStyle}
            />}
          </Link>
        </div>

        <div className={`dreams-unlocked ${location.pathname === '/contactus' ? 'active' : ''}`}>  
        <Link onClick={() => setIsHamburgerOpen(false)} to="/contactus">
          <div className="dreams-unlocked">Contact us</div>
        </Link>
        </div> */}

        <Link onClick={() => setIsHamburgerOpen(false)} to="/booking">
          <div className={`confirmation-number-parent ${location.pathname === '/' ? 'active' : ''}`}>
            <img
              className="confirmation-number-icon"
              alt=""
              src="/images/confirmation-number.svg"
            />
            <div className="dreams-unlocked">Book Tickets</div>
          </div>
        </Link>

      </div>
    </div>
  );
};

export default Header;
