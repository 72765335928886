import Header from "../components/Header";
import Footer from "../components/Footer";
import SectionComponent from "../components/SectionComponent";
import "./Desktop2.css";
import { Carousel } from 'react-responsive-carousel';

const Desktop2 = () => {
  return (
    <div className="desktop-3">
      <Header
        vector13Stroke="/vector-13.svg"
        vector13Stroke1="/vector-131.svg"
        vector13StrokeHeight="unset"
        vector13StrokeMaxHeight="100%"
        vector13StrokeHeight1="unset"
        vector13StrokeMaxHeight1="100%"
      />
      <SectionComponent />
      <div className="frame-parent16">
        <div className="frame-parent17">
          <div className="what-is-tedx-bnmit-wrapper">
            <div className="the-team">
              <p className="tedxbnmit-is-a-dynamic-platfor">What is</p>
              <p className="tedx-bnmit2">
                <span className="tedx5">{`TEDx `}</span>
                <span>
                  <span>BNMIT</span>
                  <span className="span8">?</span>
                </span>
              </p>
            </div>
          </div>
          <div className="tedxbnmit-is-a-dynamic-platfor-wrapper">
            <div className="tedxbnmit-is-a-container">
              <p className="tedxbnmit-is-a-dynamic-platfor">
                <span className="tedx5">TEDx</span>
                <span className="bnmit2">BNMIT</span>
                <span className="is-a-dynamic">
                  {" "}
                  is a dynamic platform at BNMIT, blending cultural richness and
                  intellectual growth. This event fosters innovative dialogues,
                  showcasing diverse perspectives on culture, technology, and
                  personal development. Through inspiring talks and
                  performances, TEDxBNMIT cultivates an environment that
                  celebrates cultural diversity while nurturing individual and
                  collective growth.
                </span>
              </p>
              <p className="blank-line">&nbsp;</p>
              <p className="blank-line">
                Established in 2013, we’ve conducted events in 2015 and 2018 as
                well
              </p>
            </div>
          </div>
        </div>

        <br></br>
      </div>
      <div className="frame-parent18">
        <div className="the-team-parent">
          <b className="the-team">The Team</b>
          <div className="tedx-bnmit3">
            <span className="tedx7">TEDx</span>
            <span className="bnmit3">BNMIT</span>
          </div>
        </div>
        <div className="rectangle-group">
          <div className="frame-parent20">
            <div className="group-wrapper">
              <img className="frame-child24" alt="" src="/images/maanay.png" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper">
              <img className="frame-child24" alt="" src="/images/priya.png" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper">
              <img className="frame-child24" alt="" src="/images/anusha.png" />
            </div>
          </div>
        </div>
        <div className="rectangle-group">
          <div className="frame-parent20">
            <div className="group-wrapper">
              <img className="frame-child24" alt="" src="/images/shamanth.png" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper">
              <img className="frame-child24" alt="" src="/images/Joshitha.png" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper">
              <img className="frame-child24" alt="" src="/images/bindhu.png" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper">
              <img className="frame-child24" alt="" src="/images/karthik.png" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper">
              <img className="frame-child24" alt="" src="/images/hemashri.png" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper">
              <img className="frame-child24" alt="" src="/images/ujwal.png" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper">
              <img className="frame-child24" alt="" src="/images/Akshay.png" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper">
              <img className="frame-child24" alt="" src="/images/anuradha.png" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper">
              <img className="frame-child24" alt="" src="/images/adithya.png" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper">
              <img className="frame-child24" alt="" src="/images/harshitha.png" />
            </div>
          </div>

        </div>
      </div>
      <div className="frame-parent5">
        <div className="gallery-parent">
          <b className="what-is-ted-container">Gallery</b>
          <div className="tedx-bnmit">
            <span className="ted1">TEDx </span>
            <span className="bnmit">BNMIT</span>
          </div>
        </div>
        <div className="rectangle-parent">

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/IMG_3058.JPG" />
            </div>
          </div>
          
          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/IMG_3061.JPG" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/booth.jpeg" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/signage-ted.jpeg" />
            </div>
          </div>
                  
          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/IMG_3086.JPG" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/IMG_3099.JPG" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/core.jpeg" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/IMG_3108.JPG" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/IMG_3122.JPG" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/crowd1.jpg" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/crowd2.jpg" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/volunteers.jpeg" />
            </div>
          </div>
          
        </div>
        <div className="carousel-container">
          <Carousel
            showThumbs={false}
            infiniteLoop={true}
            interval={5000}
            autoPlay={true}
            emulateTouch={true}
            showStatus={false}
          >
            <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/IMG_3058.JPG" />
            </div>
          </div>
          
          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/IMG_3061.JPG" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/booth.jpeg" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/signage-ted.jpeg" />
            </div>
          </div>
          </Carousel>
        </div>
        <div className="carousel-container">
          <Carousel
            showThumbs={false}
            infiniteLoop={true}
            interval={5000}
            autoPlay={true}
            emulateTouch={true}
            showStatus={false}
          >
             <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/IMG_3086.JPG" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/IMG_3099.JPG" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/core.jpeg" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/IMG_3108.JPG" />
            </div>
          </div>
          </Carousel>
        </div>
        <div className="carousel-container">
          <Carousel
            showThumbs={false}
            infiniteLoop={true}
            interval={5000}
            autoPlay={true}
            emulateTouch={true}
            showStatus={false}
          >
            <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/IMG_3122.JPG" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/volunteers.jpeg" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/crowd1.jpg" />
            </div>
          </div>

          <div className="frame-parent20">
            <div className="group-wrapper25">
              <img className=" frame-child25" alt="" src="/images/crowd2.jpg" />
            </div>
          </div>

          </Carousel>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Desktop2;
